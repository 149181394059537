import React from "react"
import RecruitmentPl from "./recruitment.pl"
import RecruitmentEn from "./recruitment.en"
import RecruitmentDe from "./recruitment.de"

const RecruitmentContent = ({ lang }) => {
  if (lang === "de") {
    return <RecruitmentDe />
  } else if (lang === "en") {
    return <RecruitmentEn />
  }
  return <RecruitmentPl />
}

export default RecruitmentContent
