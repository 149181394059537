import React from "react"

const RecruitmentPl = () => <>
<h2>Rekrutacja</h2>

<p className="bigger-text text-center">
  Rekrutacja na stanowiska:<br />
</p>
<div className="row">
  <div className="col-12 col-md-12 col-lg-12">
    <ul>
      <li>Lakiernik hydrodynamiczny konstrukcji wielkogabarytowych, wymagane doświadczenie zawodowe, obsługa pomp
        lakierniczych, wind lakierniczych, kabin lakierniczych.
      </li>
      <li>Śruciarz konstrukcji stalowych wielkogabarytowych, wymagane doświadczenie zawodowe, obsługa osprzętu i
        kabiny śrutowniczej.
      </li>
    </ul>
    <p>CV prosimy kierować na adres <a href={"mailto:a.bruderek@hibrid.com.pl"}>a.bruderek@hibrid.com.pl</a></p>
  </div>
</div>
</>

export default RecruitmentPl
